export const siteTitle = 'Smart Tech Windows & Doors Winnipeg';

export const formBackendUrl = 'https://api.sellmorewindows.com/dmwkfenarsienst.php';

export const citiesForForm = [
  'Winnipeg',
  'Steinbach',
  'Selkirk',
  'Stonewall',
  'Niverville',
  'Oakbank',
];

export const sentryDsn = '';
